<template>
  <div>
    <b-modal
      id="modal-edit-puskesmas"
      size="lg"
      centered
      title="Update Data Puskesmas"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
      @shown="getProvinsi()"
    >
      <b-overlay :show="overlayModalEditPuskesmas">
        <template #overlay>
          <div class="text-center">
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
            <p id="cancel-label">Mohon Tunggu...</p>
          </div>
        </template>
        <div v-if="is_data">
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Puskesmas <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('nama_puskesmas')"
              type="text"
              v-model="$v.is_data.nama_puskesmas.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Alamat <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.alamat_puskesmas.$model"
              type="text"
              :state="checkIfValid('alamat_puskesmas')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Cons ID <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.cons_id.$model"
              type="text"
              :state="checkIfValid('cons_id')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Secret Key <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.secret_key.$model"
              type="text"
              :state="checkIfValid('secret_key')"
            ></b-form-input>
          </b-form-group>
          <!-- <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kode Aplikasi <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.kode_aplikasi.$model"
              type="text"
              :state="checkIfValid('kode_aplikasi')"
            ></b-form-input>
          </b-form-group> -->
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              User Key <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.user_key.$model"
              type="text"
              :state="checkIfValid('user_key')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tarif Pendaftaran <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.tarif_pendaftaran.$model"
              type="number"
              :state="checkIfValid('tarif_pendaftaran')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kode Puskesmas <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.kode_puskesmas.$model"
              type="text"
              :state="checkIfValid('kode_puskesmas')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Garis Bujur <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.lng.$model"
              type="text"
              :state="checkIfValid('lng')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Garis Lintang <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.lat.$model"
              type="text"
              :state="checkIfValid('lat')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Username <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.username_puskesmas.$model"
              type="text"
              :state="checkIfValid('username_puskesmas')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Password <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.password_puskesmas.$model"
              type="text"
              :state="checkIfValid('password_puskesmas')"
            ></b-form-input>
          </b-form-group>
          <!-- <b-form-group label-cols-md="3">
            <template v-slot:label>
              Secret Key Satu Sehat <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.is_data.secret_key_satu_sehat.$model"
              type="text"
              :state="checkIfValid('secret_key_satu_sehat')"
            ></b-form-input>
          </b-form-group> -->
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Email Puskesmas
            </template>
            <b-form-input
              v-model="$v.is_data.email_pengaduan.$model"
              type="text"
              :state="checkIfValid('email_pengaduan')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No. Telp Puskesmas
            </template>
            <b-form-input
              v-model="$v.is_data.no_telp_pengaduan.$model"
              type="number"
              :state="checkIfValid('no_telp_pengaduan')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Provinsi <span class="text-danger">*</span>
            </template>
            <Multiselect
              id="ms_provinsi"
              v-model="provinsi_obj"
              :options="option_provinsi"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="-- Pilih Provinsi --"
              size="sm"
              track-by="ms_provinsi_id"
              label="nama_provinsi"
              @select="getKota(provinsi_obj)"
              :state="checkIfValid('ms_provinsi_id')"
            ></Multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kota <span class="text-danger">*</span>
            </template>
            <Multiselect
              id="ms_kota"
              v-model="kota_obj"
              :options="option_kota"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="-- Pilih kota --"
              size="sm"
              track-by="ms_kab_kota_id"
              label="nama_kota"
              @select="getKecamatan(kota_obj)"
              :state="checkIfValid('ms_kab_kota_id')"
            ></Multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kecamatan <span class="text-danger">*</span>
            </template>
            <Multiselect
              id="ms_kecamatan"
              v-model="kecamatan_obj"
              :options="option_kecamatan"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="-- Pilih kecamatan --"
              size="sm"
              track-by="ms_kecamatan_id"
              label="nama_kecamatan"
              @select="getKelurahan(kecamatan_obj)"
              :state="checkIfValid('ms_kecamatan_id')"
            ></Multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Kelurahan <span class="text-danger">*</span>
            </template>
            <Multiselect
              id="ms_kelurahan"
              v-model="kelurahan_obj"
              :options="option_kelurahan"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="-- Pilih kelurahan --"
              size="sm"
              track-by="ms_kelurahan_id"
              label="nama_kelurahan"
              @select="selectKelurahan(kelurahan_obj)"
              :state="checkIfValid('ms_kelurahan_id')"
            ></Multiselect>
          </b-form-group>
          <h4>SATU SEHAT</h4>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Secret Key
            </template>
            <b-form-input
              v-model="is_data.satu_sehat_client_secret"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Client
            </template>
            <b-form-input
              v-model="is_data.satu_sehat_client_id"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Organization
            </template>
            <b-form-input
              v-model="is_data.organization_id"
              type="text"
            ></b-form-input>
          </b-form-group>
        </div>
      </b-overlay>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-puskesmas')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Multiselect from 'vue-multiselect'

export default {
  name: "modalEdit",
  props: [
    "fileName",
    "data_edit",
  ],
  // components: {
  //   Multiselect,
  // },
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: {} || null,
      file_name: this.fileName,

      provinsi_obj: [],
      kota_obj: [],
      kecamatan_obj: [],
      kelurahan_obj: [],
      
      option_kelurahan: [],
      option_kecamatan: [],
      option_kota: [],
      option_provinsi: [],

      overlayModalEditPuskesmas: false
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_") ? this.file_name.replace("ms_", "").split("_") : this.file_name.split("_")
      let capitalizedWords = words.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1); });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      this.getProvinsi()
      this.watcherDataEdit(newVal)
      console.log(this.is_data,'asda');
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_puskesmas: {},
      alamat_puskesmas: {},
      cons_id: {},
      secret_key: {},
      kode_aplikasi: {},
      user_key: {},
      tarif_pendaftaran: {},
      kode_puskesmas: {},
      lng: {},
      lat: {},
      username_puskesmas: {},
      password_puskesmas: {},
      // secret_key_satu_sehat: {},
      no_telp_pengaduan: {},
      email_pengaduan: {},
      ms_provinsi_id: {},
      ms_kab_kota_id: {},
      ms_kecamatan_id: {},
      ms_kelurahan_id: {}
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";

      vm.$axios
        .post(`/${vm.file_name}/update`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-puskesmas");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
      this.resetModal()
      
      this.provinsi_obj = []
      this.kota_obj = []
      this.kecamatan_obj = []
      this.kelurahan_obj = []
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = null
    },
    async getProvinsi() {
      const vm = this
      try {
        let prov = await vm.$axios.post("ms_provinsi/list")
        vm.option_provinsi = prov.data.status == 200 ? prov.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKota(val) {
      const vm = this
      // console.log(val, 'ini val');
      let x = { ms_provinsi_id: val ? val.ms_provinsi_id : null }
      vm.kota_obj = []
      vm.kecamatan_obj = []
      vm.kelurahan_obj = []
      
      vm.is_data.ms_provinsi_id = val.ms_provinsi_id || ""
      vm.is_data.ms_kab_kota_id = ""
      vm.is_data.ms_kecamatan_id = ""
      vm.is_data.ms_kelurahan_id = ""
      if (_.values(x).every(_.isEmpty)) return

      try {
        let kot = await vm.$axios.post("ms_kota/list", x)
        vm.option_kota = kot.data.status == 200 ? kot.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKecamatan(val) {
      const vm = this
      let data = val.ms_kota_id ? val.ms_kota_id : val.ms_kab_kota_id
      let x = { ms_kab_kota_id: data ? data : null }
      vm.kecamatan_obj = []
      vm.kelurahan_obj = []

      vm.is_data.ms_kab_kota_id = data || ""
      vm.is_data.ms_kecamatan_id = ""
      vm.is_data.ms_kelurahan_id = ""
      if (_.values(x).every(_.isEmpty)) return

      try {
        let kec = await vm.$axios.post("ms_kecamatan/list", x)
        vm.option_kecamatan = kec.data.status == 200 ? kec.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKelurahan(val) {
      const vm = this
      let x = { ms_kecamatan_id: val ? val.ms_kecamatan_id : null }
      vm.kelurahan_obj = []

      vm.is_data.ms_kecamatan_id = val.ms_kecamatan_id || ""
      if (_.values(x).every(_.isEmpty)) return

      try {
        let kel = await vm.$axios.post("ms_kelurahan/list", x)
        vm.option_kelurahan = kel.data.status == 200 ? kel.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async selectKelurahan(val) {
      this.is_data.ms_kelurahan_id = val.ms_kelurahan_id || ""
    },
    async watcherDataEdit(val) {
      this.overlayModalEditPuskesmas = true
      // console.log("watcher", val);
      let newVal = {...val}
      this.is_data = {}
      // console.log("newVal", newVal);
      // console.log('provinsi', this.option_provinsi);
      await this.getKota(newVal)
      await this.getKecamatan(newVal)
      await this.getKelurahan(newVal)
      await this.selectKelurahan(val)

      this.is_data.id = newVal.id;
      this.is_data.nama_puskesmas = newVal.nama_puskesmas;
      this.is_data.alamat_puskesmas = newVal.alamat_puskesmas;
      this.is_data.cons_id = newVal.cons_id;
      this.is_data.secret_key = newVal.secret_key;
      this.is_data.kode_aplikasi = newVal.kode_aplikasi;
      this.is_data.user_key = newVal.user_key;
      this.is_data.tarif_pendaftaran = newVal.tarif_pendaftaran;
      this.is_data.kode_puskesmas = newVal.kode_puskesmas;
      this.is_data.lng = newVal.lng; // bujur
      this.is_data.lat = newVal.lat; // lintang
      this.is_data.email_pengaduan = newVal.email_pengaduan
      this.is_data.no_telp_pengaduan = newVal.no_telp_pengaduan
      this.is_data.username_puskesmas = newVal.username_puskesmas;
      this.is_data.password_puskesmas = newVal.password_puskesmas;
      this.is_data.organization_id = newVal.organization_id;
      this.is_data.satu_sehat_client_id = newVal.satu_sehat_client_id;
      this.is_data.satu_sehat_client_secret = newVal.satu_sehat_client_secret;
      this.provinsi_obj = this.option_provinsi.find(o => o.ms_provinsi_id == newVal.ms_provinsi_id)
      this.kota_obj = this.option_kota.find(o => o.ms_kota_id == newVal.ms_kab_kota_id)
      this.kecamatan_obj = this.option_kecamatan.find(o => o.ms_kecamatan_id == newVal.ms_kecamatan_id)
      this.kelurahan_obj = this.option_kelurahan.find(o => o.ms_kelurahan_id == newVal.ms_kelurahan_id)
      // console.log("is_data_edit", this.is_data);

      this.overlayModalEditPuskesmas = false
    }
  },
};
</script>
