<template>
  <div>
    <b-modal
      id="modal-input-puskesmas"
      size="lg"
      centered
      :title="'Tambah Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
      @shown="getProvinsi()"
    >
      <!-- <pre>{{ $v }}</pre> -->
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Puskesmas <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_puskesmas')"
            type="text"
            v-model="$v.is_data.nama_puskesmas.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Alamat <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.alamat_puskesmas.$model"
            type="text"
            :state="checkIfValid('alamat_puskesmas')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Cons ID <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.cons_id.$model"
            type="text"
            :state="checkIfValid('cons_id')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Secret Key <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.secret_key.$model"
            type="text"
            :state="checkIfValid('secret_key')"
          ></b-form-input>
        </b-form-group>
        <!-- <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kode Aplikasi <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.kode_aplikasi.$model"
            type="text"
            :state="checkIfValid('kode_aplikasi')"
          ></b-form-input>
        </b-form-group> -->
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            User Key <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.user_key.$model"
            type="text"
            :state="checkIfValid('user_key')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tarif Pendaftaran <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.tarif_pendaftaran.$model"
            type="number"
            :state="checkIfValid('tarif_pendaftaran')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kode Puskesmas <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.kode_puskesmas.$model"
            type="text"
            :state="checkIfValid('kode_puskesmas')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Garis Bujur <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.lng.$model"
            type="text"
            :state="checkIfValid('lng')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Garis Lintang <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.lat.$model"
            type="text"
            :state="checkIfValid('lat')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Username <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.username_puskesmas.$model"
            type="text"
            :state="checkIfValid('username_puskesmas')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Password <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.password_puskesmas.$model"
            type="text"
            :state="checkIfValid('password_puskesmas')"
          ></b-form-input>
        </b-form-group>
        <!-- <b-form-group label-cols-md="3">
          <template v-slot:label>
            Secret Key Satu Sehat <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.secret_key_satu_sehat.$model"
            type="text"
            :state="checkIfValid('secret_key_satu_sehat')"
          ></b-form-input>
        </b-form-group> -->
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Provinsi <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_provinsi"
            v-model="provinsi_obj"
            :options="option_provinsi"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih Provinsi --"
            size="sm"
            track-by="ms_provinsi_id"
            label="nama_provinsi"
            @select="getKota(provinsi_obj)"
            :state="checkIfValid('ms_provinsi_id')"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kota <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_kota"
            v-model="kota_obj"
            :options="option_kota"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih kota --"
            size="sm"
            track-by="ms_kab_kota_id"
            label="nama_kota"
            @select="getKecamatan(kota_obj)"
            :state="checkIfValid('ms_kab_kota_id')"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kecamatan <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_kecamatan"
            v-model="kecamatan_obj"
            :options="option_kecamatan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih kecamatan --"
            size="sm"
            track-by="ms_kecamatan_id"
            label="nama_kecamatan"
            @select="getKelurahan(kecamatan_obj)"
            :state="checkIfValid('ms_kecamatan_id')"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kelurahan <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_kelurahan"
            v-model="kelurahan_obj"
            :options="option_kelurahan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih kelurahan --"
            size="sm"
            track-by="ms_kelurahan_id"
            label="nama_kelurahan"
            @select="selectKelurahan(kelurahan_obj)"
            :state="checkIfValid('ms_kelurahan_id')"
          ></Multiselect>
        </b-form-group>
        <h4>SATU SEHAT</h4>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Secret Key
            </template>
            <b-form-input
              v-model="is_data.satu_sehat_client_secret"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Client
            </template>
            <b-form-input
              v-model="is_data.satu_sehat_client_id"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Organization
            </template>
            <b-form-input
              v-model="is_data.organization_id"
              type="text"
            ></b-form-input>
          </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-puskesmas')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props: [
    "fileName",
  ],
  data() {
    return {
      is_data: {
        nama_puskesmas: null,
        alamat_puskesmas: null,
        cons_id: null,
        secret_key: null,
        kode_aplikasi: "095",
        user_key: null,
        tarif_pendaftaran: null,
        kode_puskesmas: null,
        lng: null,
        lat: null,
        username_puskesmas: null,
        password_puskesmas: null,
        // secret_key_satu_sehat: null,
        ms_provinsi_id: null,
        ms_kab_kota_id: null,
        ms_kecamatan_id: null,
        ms_kelurahan_id: null,
        satu_sehat_client_secret: null,
        satu_sehat_client_id: null,
        organization_id: null,
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
      file_name: this.fileName,

      provinsi_obj: [],
      kota_obj: [],
      kecamatan_obj: [],
      kelurahan_obj: [],

      option_kelurahan: [],
      option_kecamatan: [],
      option_kota: [],
      option_provinsi: [],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_") ? this.file_name.replace("ms_", "").split("_") : this.file_name.split("_")
      let capitalizedWords = words.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1); });
      return capitalizedWords.join(" ");
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_puskesmas: { required },
      alamat_puskesmas: { required },
      cons_id: { required },
      secret_key: { required },
      kode_aplikasi: {},
      user_key: { required },
      tarif_pendaftaran: { required },
      kode_puskesmas: { required },
      lng: { required },
      lat: { required },
      username_puskesmas: { required },
      password_puskesmas: { required },
      // secret_key_satu_sehat: {},

      ms_provinsi_id: { required },
      ms_kab_kota_id: { required },
      ms_kecamatan_id: { required },
      ms_kelurahan_id: { required }
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      
      // vm.is_data.ms_provinsi_id = provinsi_obj.ms_provinsi_id
      // vm.is_data.ms_kab_kota_id = provinsi_obj.ms_kab_kota_id
      // vm.is_data.ms_kecamatan_id = provinsi_obj.ms_kecamatan_id
      // vm.is_data.ms_kelurahan_id = provinsi_obj.ms_kelurahan_id

      vm.$axios
        .post(`/${vm.file_name}/register`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN " + this.pageName.toUpperCase(),
              showing: true,
            });
            this.$bvModal.hide("modal-input-puskesmas");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    async getProvinsi() {
      const vm = this
      try {
        let prov = await vm.$axios.post("ms_provinsi/list")
        vm.option_provinsi = prov.data.status == 200 ? prov.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKota(val) {
      const vm = this
      let x = { ms_provinsi_id: val ? val.ms_provinsi_id : null }
      vm.kota_obj = []
      vm.kecamatan_obj = []
      vm.kelurahan_obj = []
      
      vm.is_data.ms_provinsi_id = val.ms_provinsi_id
      vm.is_data.ms_kab_kota_id = ""
      vm.is_data.ms_kecamatan_id = ""
      vm.is_data.ms_kelurahan_id = ""
      try {
        let kot = await vm.$axios.post("ms_kota/list", x)
        vm.option_kota = kot.data.status == 200 ? kot.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKecamatan(val) {
      const vm = this
      let x = { ms_kab_kota_id: val ? val.ms_kota_id : null }
      vm.kecamatan_obj = []
      vm.kelurahan_obj = []

      vm.is_data.ms_kab_kota_id = val.ms_kota_id
      vm.is_data.ms_kecamatan_id = ""
      vm.is_data.ms_kelurahan_id = ""
      try {
        let kec = await vm.$axios.post("ms_kecamatan/list", x)
        vm.option_kecamatan = kec.data.status == 200 ? kec.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKelurahan(val) {
      const vm = this
      let x = { ms_kecamatan_id: val ? val.ms_kecamatan_id : null }
      vm.kelurahan_obj = []

      vm.is_data.ms_kecamatan_id = val.ms_kecamatan_id
      try {
        let kel = await vm.$axios.post("ms_kelurahan/list", x)
        vm.option_kelurahan = kel.data.status == 200 ? kel.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    selectKelurahan(val) {
      this.is_data.ms_kelurahan_id = val.ms_kelurahan_id
    },
    removeDataObj(val) {
      const vm = this
      if (val == 'provinsi') {
        this.is_data.kota_obj = []
        this.is_data.kecamatan_obj = []
        this.is_data.kelurahan_obj = []
      } else if (val == 'kota') {
        this.is_data.kecamatan_obj = []
        this.is_data.kelurahan_obj = []
      } else if (val == 'kecamatan') {
        this.is_data.kelurahan_obj = []
      }
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_puskesmas: null,
        alamat_puskesmas: null,
        cons_id: null,
        secret_key: null,
        kode_aplikasi: "095",
        user_key: null,
        tarif_pendaftaran: null,
        kode_puskesmas: null,
        lng: null,
        lat: null,
        username_puskesmas: null,
        password_puskesmas: null,
        // secret_key_satu_sehat: null,
        ms_provinsi_id: null,
        ms_kab_kota_id: null,
        ms_kecamatan_id: null,
        ms_kelurahan_id: null
      },
      this.provinsi_obj = []
      this.kota_obj = []
      this.kecamatan_obj = []
      this.kelurahan_obj = []
    },
  },
};
</script>
